import React from 'react'
import style from './index.module.less'
import { joinus_img1, joinus_img2, joinus_img3} from '../../../images/ultra'
import {EmailBox} from '../index'
import { useIntl } from 'react-intl'
const JoinUs = () =>{
    const intl = useIntl()
    const intro_list=[
        {
            img:joinus_img1,
            desc:intl.formatMessage({ id: "ces.come.display.desc1" }),
            link:intl.formatMessage({ id: "ces.come.display.desc4" })
        },
        {
            img:joinus_img2,
            desc:intl.formatMessage({ id: "ces.come.display.desc2" }),
            link:""
        },
        {
            img:joinus_img3,
            desc:intl.formatMessage({ id: "ces.come.display.desc3" }),
            link:intl.formatMessage({ id: "ces.come.display.desc5" })
        }
    ]
    return(
        <div className={style.box}>
            <div className={style.boxTitle}>{intl.formatMessage({ id: "ces.come.title" })}</div>
            <div className={style.boxDesc}>{intl.formatMessage({ id: "ces.come.display" })}</div>
            <div className={style.intro}>
                {intro_list.map((item,index)=>(
                    <div className={style.introBox} key={`joinusIntroBox${index}`}>
                        <div className={style.introBoxImg} style={{backgroundImage:`url(${item.img})`}}></div>
                        <div className={style.introBoxContent}>
                            <div className={style.introBoxContentDesc}  dangerouslySetInnerHTML={{ __html:item.desc }}></div>
                            {!!item.link&&<div  dangerouslySetInnerHTML={{ __html:item.link }} className={style.introBoxContentTip}></div>}
                        </div>
                    </div>
                ))}
            </div>
            <EmailBox desc={`${intl.formatMessage({ id: "ces.come.display.desc6" })}<br/>${intl.formatMessage({ id: "ces.come.display.desc7" })}`}  gaID='developer_ultra_home_subscribe2'/>
        </div>
    )
}

export default JoinUs