import React , {useEffect,useRef} from 'react'
import style from './index.module.less'
import { sdkVideo } from '../../../images/ultra'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useIntl } from 'react-intl'

const SDKBox = () => {
    const intl=useIntl()
    const block1Ref = useRef()
    const block2Ref = useRef()
    const coverRef = useRef()
    const block2TextRef = useRef()
    gsap.registerPlugin(ScrollTrigger)

    useEffect(()=>{
        const t1= gsap.timeline({
            scrollTrigger: {
                start: "center bottom",
                end: "center top",
                trigger: block1Ref.current,
                scrub: true,
            }
        })
      .to(block1Ref.current, {opacity: 1})
      .to(block1Ref.current, {opacity: 0})
        gsap.to(coverRef.current, {
            top: 0,
            scrollTrigger: {
                start: "top bottom",
                end: "top top",
                trigger: block1Ref.current,
                scrub: true,
            }
        });
        gsap.to(block2Ref.current, {
            opacity: 1,
            scrollTrigger: {
                start: "center bottom",
                end: "top top",
                trigger: block2Ref.current,
                scrub: true,
            }
        });
    },[])
    useEffect(() => {
        const handleResize = () => {
            ScrollTrigger.refresh();
        };
    
        const resizeObserver = new ResizeObserver(handleResize);

        resizeObserver.observe(document.documentElement);
    
        return () => {
          resizeObserver.disconnect();
        };
      }, []);
    return (
        <div className={style.box}>
            <div className={style.boxVideo}>
                <video autoPlay playsInline loop muted width="100%" >
                    <source src={sdkVideo} type="video/mp4" />
                </video>
                <div className={style.boxVideoCover} ref={coverRef}></div>
               
            </div>
            <div className={style.boxContent}>
                <div className={style.block} ref={block1Ref}>
                    <div className={style.blockTitle}>{intl.formatMessage({id:"ces.pic1.desc1"})}</div>
                    <div className={style.blockTip}>
                    {intl.formatMessage({id:"ces.pic1.desc2"})}
                    </div>
                </div>
                <div className={style.block} ref={block2Ref}>
                    <div className={style.blockTitle}> {intl.formatMessage({id:"ces.pic1.desc3"})}</div>
                    <div className={style.blockDesc} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({id:"ces.pic1.desc4"})}` }}></div>
                    <div className={style.blockTip} ref={block2TextRef}>{intl.formatMessage({id:"ces.pic1.desc5"})}</div>
                    <div className={style.blockCommit}>
                    {intl.formatMessage({id:"ces.pic1.desc6"})}
                    </div>
                </div>
            </div>

        </div>
    )
}
export default SDKBox