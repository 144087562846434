import React from 'react'
import style from './index.module.less'
import {comment_icon1, comment_icon2,} from '../../../images/ultra'
import { useIntl } from 'react-intl'
const DeveloperSay = () =>{
    const intl = useIntl()
    const comment_list=[
        {
            text:intl.formatMessage({ id: "ces.saying.display1" }),
            name:intl.formatMessage({ id: "ces.saying.display1.desc1" }),
            profession:intl.formatMessage({ id: "ces.saying.display1.desc2" }),
            icon:comment_icon1
        },
        {
            text:intl.formatMessage({ id: "ces.saying.display2" }),
            name:intl.formatMessage({ id: "ces.saying.display2.desc1" }),
            profession:intl.formatMessage({ id: "ces.saying.display2.desc2" }),
            icon:comment_icon2
        }
    ]

    return(
        <div className={style.box}>
            <div className={style.boxTitle}>{intl.formatMessage({ id: "ces.saying.title" })}</div>
            <div className={style.comment}>
                {comment_list.map((item,index)=>(
                    <div className={style.commentBox} key={`commentItem${index}`}>
                        <div className={style.commentBoxText}>{item.text}</div>
                        <div className={style.commentBoxIntro}>
                            <div className={style.commentBoxIntroImg} style={{backgroundImage:`url(${item.icon})`}}></div>
                            <div className={style.commentBoxIntroDesc}>
                                <div className={style.commentBoxIntroDescName}>{item.name}</div>
                                <div className={style.commentBoxIntroDescPro}>{item.profession}</div>
                            </div>
                        </div>
                        <div className={style.commentBoxTriangle}></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DeveloperSay