import React, { useEffect, useRef } from 'react'
import style from './index.module.less'
import classNames from 'classnames'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useIntl } from 'react-intl'
const Building = () => {
    const intl = useIntl()
    gsap.registerPlugin(ScrollTrigger)
    const boxRef = useRef()
    const data = [
        {
            img: process.env.resourceUrl + 'images/ultra/building1.jpg',
            title: `${intl.formatMessage({ id: "ces.pic2.desc1" })}`,
            desc: `${intl.formatMessage({ id: "ces.pic2.desc2" })}`
        },
        {
            img: process.env.resourceUrl + 'images/ultra/ces_building2.webp',
            title: `${intl.formatMessage({ id: "ces.pic2.desc3" })}`,
            desc: `${intl.formatMessage({ id: "ces.pic2.desc4" })}`
        },
        {
            img: process.env.resourceUrl + 'images/ultra/building3.png',
            title: `${intl.formatMessage({ id: "ces.pic2.desc5" })}`,
            desc: `${intl.formatMessage({ id: "ces.pic2.desc6" })}`
        },
        {
            img: process.env.resourceUrl + 'images/ultra/building4.png',
            title: `${intl.formatMessage({ id: "ces.pic2.desc7" })}`,
            desc: `${intl.formatMessage({ id: "ces.pic2.desc8" })}`,
        }, {
            img: process.env.resourceUrl + 'images/ultra/building5.jpg',
            title: `${intl.formatMessage({ id: "ces.pic2.desc9" })}`,
            desc: `${intl.formatMessage({ id: "ces.pic2.desc10" })}`
        }, ,
        {
            img: process.env.resourceUrl + 'images/ultra/building6.jpg',
            title: `${intl.formatMessage({ id: "ces.pic2.desc11" })}`,
            desc: `${intl.formatMessage({ id: "ces.pic2.desc12" })}`
        },
    ]
    useEffect(() => {
        const boxes = gsap.utils.toArray('.buildingItem');
        boxes.forEach(box => {
            ScrollTrigger.create({
                trigger: box,
                start: "center 80%",
                onEnter: () => {
                    gsap.to(box, {
                        opacity: '1'
                    })
                }
            })
            ScrollTrigger.create({
                trigger: box,
                start: "center bottom",
                onLeaveBack: () => {
                    gsap.to(box, {
                        opacity: '0'
                    })
                }
            })
        });

    }, [intl])
    return (
        <div className={style.build}>
            {/* <div className={style.title}>{intl.formatMessage({ id: "home.buildtoday" })}</div> */}
            <div className={style.buildBox} ref={boxRef}>
                {data.map(item => (
                    <div className={style.buildBoxItem} key={`buildingBoxItem${item.title}`}
                        style={{ backgroundImage: `url(${item.img})` }}
                    >
                        <div className={classNames(style.buildBoxItemCover, 'buildingItem')}>
                            <div className={style.buildBoxItemCoverTitle}>{item.title}</div>
                            <div className={style.buildBoxItemCoverDesc} dangerouslySetInnerHTML={{ __html: `${item.desc}` }}></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Building