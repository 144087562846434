import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.less'
import {
    ultra_glass_1, air2ultra_logo, ultra_modal, firstVideo, block6_bg,
    block7_bg,
} from '../../../images/ultra/index'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useWinSize from '../../../hooks/useWinSize'
import getObjectFitSize from "../../../utils/getObjFitSize"
import useBigScreen from "@/hooks/bigScreen"
import LinkArrow from '../../../images/SVG/link_arrow.svg'
import { useIntl } from 'react-intl';
import useGa from "@/hooks/useGa"
import { useGTM } from '@/hooks/useGTM.js'

const isBrowser = typeof window !== "undefined" && window

let frameCount = 19;
const images = [];
for (let i = 1; i < frameCount + 1; i++) {
    images[i - 1] = `${process.env.resourceUrl}images/ultra/frame/${i}.webp`;
}
const firstImg = `${process.env.resourceUrl}images/ultra/frame/1.webp`
function preloadImages(urls) {
    const promises = urls.map((url) => {
        return new Promise((resolve, reject) => {
            const image = isBrowser && new Image()
            image.src = url;
            image.onload = () => resolve(image);
            image.onerror = () => reject(`Image failed to load: ${url}`);
        });
    });
    return Promise.all(promises);
}

const FirstScreen = ({ultra_link}) => {
    const intl = useIntl()
    gsap.registerPlugin(ScrollTrigger)
    const size = useWinSize()
    const [curIdx, setCurIdx] = useState(0)
    const boxRef = useRef()
    const box1Ref = useRef()
    const box2Ref = useRef()
    const box3Ref = useRef()
    const box4Ref = useRef()
    const box5Ref = useRef()
    const block1Ref = useRef()   //第一屏👓
    const block2Ref = useRef()  //白毛女人
    const block3Ref = useRef() //黑色蒙版
    const block4Ref = useRef() //视频
    const block6Ref = useRef() //视频最后的蒙版
    const block7Ref = useRef() //第二个视频最后的蒙版
    const block8Ref = useRef() //最后一屏转圈圈
    const boxStickyRef = useRef()
    const text1Ref = useRef()
    const text2Ref = useRef()
    const text3Ref = useRef()
    const block6TextRef = useRef()
    const block7TextRef = useRef()
    const block6BgRef = useRef()
    const block7BgRef = useRef()
    const womanRef = useRef() //转圈的白毛女人
    const canvasRef = useRef()
    const woman_text1 = useRef()
    const woman_text2 = useRef()
    const woman_text3 = useRef()
    const woman_text4 = useRef()
    const woman_text5 = useRef()
    const block8CoverRef = useRef()
    const [imagesAry, setImagesAry] = useState([])
    const isBigScreen = useBigScreen(768)

    useEffect(() => {
        let imgAry = []
        const fetchData = async () => {
            imgAry = await preloadImages(images);
            setImagesAry(imgAry);
        }
        fetchData()

    }, [])
    const addModeleView = ()=>{
      
    }
    useEffect(() => {
        gsap.to(block1Ref.current, {
            opacity: 0,
            scale: 1,
            scrollTrigger: {
                start: "top top",
                end: "center top",
                trigger: box1Ref.current,
                scrub: true,
                onEnter:addModeleView,              
                onEnterBack:addModeleView,
            }
        });
        gsap.to(text1Ref.current, {
            opacity: 0,
            zIndex: 1,
            scrollTrigger: {
                start: "top top",
                end: "center top",
                trigger: box1Ref.current,
                scrub: true,
            }
        });

        gsap.to(block2Ref.current, {
            opacity: 1,
            scale: 1,
            scrollTrigger: {
                start: "top top",
                end: "center top",
                trigger: box1Ref.current,
                scrub: true,
            }
        });

        gsap.to(block3Ref.current, {
            opacity: 1,
            scrollTrigger: {
                start: "48% top",
                end: "bottom top",
                trigger: box1Ref.current,
                scrub: true
            }
        });
        gsap.to(block4Ref.current, {
            opacity: 1,
            scrollTrigger: {
                start: "top top",
                end: "center top",
                trigger: box2Ref.current,
                scrub: true
            }
        });
        const tl = gsap.timeline({

            scrollTrigger: {
                start: "top top",
                end: "bottom top",
                trigger: box2Ref.current,
                scrub: true,
            },
        })
            .to(text2Ref.current, { opacity: 1 })
            .to(text2Ref.current, { opacity: 0 }, '+1')
            .to(text2Ref.current, {
                top: 0,
                y: 0,
                scrollTrigger: {
                    start: "top top",
                    end: "bottom top",
                    trigger: box2Ref.current,
                    scrub: true,
                },
            })
        const tl2 = gsap.timeline({
            scrollTrigger: {
                start: "top top",
                end: "bottom top",
                trigger: box3Ref.current,
                scrub: true,
            },
        })
            .to(text3Ref.current, { opacity: 1 })
            .to(text3Ref.current, { opacity: 0 }, '+1')
            .to(text3Ref.current, {
                top: 0,
                y: 0,
                scrollTrigger: {
                    start: "top top",
                    end: "bottom top",
                    trigger: box3Ref.current,
                    scrub: true,
                },
            })

        // 视频后出现了个黑色蒙版
        gsap.to(block6Ref.current, {
            opacity: 1,
            scrollTrigger: {
                start: "top top",
                end: "20% top",
                trigger: box4Ref.current,
                scrub: true,
            },
        })
        gsap.to(block6TextRef.current, {
            opacity: 1,
            scrollTrigger: {
                start: "20% top",
                end: "40% top",
                trigger: box4Ref.current,
                scrub: true,
            },
        })
        gsap.to(block6BgRef.current, {
            scale: 1,
            top: 0,
            // duration:200,
            scrollTrigger: {
                start: "20% top",
                end: "40% top",
                trigger: box4Ref.current,
                toggleActions: 'play none play reverse',
                // scrub: true,
            },
        })

        gsap.to(block7Ref.current, {
            opacity: 1,
            scrollTrigger: {
                start: "40% top",
                end: "60% top",
                trigger: box4Ref.current,
                scrub: true,
            },
        })
        gsap.to(block7BgRef.current, {
            scale: 1,
            top: 0,
            scrollTrigger: {
                start: "60% top",
                end: "80% top",
                trigger: box4Ref.current,
                toggleActions: 'play none play reverse',
            },
        })
        gsap.to(block7TextRef.current, {
            opacity: 1,
            scrollTrigger: {
                start: "60% top",
                end: "80% top",
                trigger: box4Ref.current,
                scrub: true,
            },
        })
        gsap.to(block8Ref.current, {
            opacity: 1,
            scrollTrigger: {
                start: "80% top",
                end: "bottom top",
                trigger: box4Ref.current,
                scrub: true,
            },
        })

        //转圈女从下升到上面&&第一个文字出现
        gsap.to(womanRef.current, {
            scale: 1,
            top: 0,
            scrollTrigger: {
                start: "top top",
                end: "10% top",
                trigger: box5Ref.current,
                scrub: true,
            },
        })


        //转盘
        let frame_count = {
            frame: 0
        };
        gsap.to(frame_count, {
            frame: 18,
            onUpdate: () => { setCurIdx(Math.floor(frame_count.frame)) },
            scrollTrigger: {
                start: "10% top",
                end: "bottom bottom",
                trigger: box5Ref.current,
                scrub: true,
            },
        })

        //转盘文字
        const tl3 = gsap.timeline({
            scrollTrigger: {
                start: "5% top",
                end: "20% top",
                trigger: box5Ref.current,
                scrub: true,
            },
        })
            .to(woman_text1.current, { opacity: 1 })
            .to(woman_text1.current, { opacity: 0 }, '+1')
            .to(woman_text1.current, {
                top: 0, y: 0,
                scrollTrigger: {
                    start: "5% top",
                    end: "20% top",
                    trigger: box5Ref.current,
                    scrub: true,
                },
            })

        const tl4 = gsap.timeline({
            scrollTrigger: {
                start: "20% top",
                end: "35% top",
                trigger: box5Ref.current,
                scrub: true,
            },
        })
            .to(woman_text2.current, { opacity: 1 })
            .to(woman_text2.current, { opacity: 0 }, '+1')
            .to(woman_text2.current, {
                top: 0, y: 0,
                scrollTrigger: {
                    start: "20% top",
                    end: "35% top",
                    trigger: box5Ref.current,
                    scrub: true,
                },
            })

        const tl5 = gsap.timeline({
            scrollTrigger: {
                start: "35% top",
                end: "50% top",
                trigger: box5Ref.current,
                scrub: true,
            },
        })
            .to(woman_text3.current, { opacity: 1 })
            .to(woman_text3.current, { opacity: 0 }, '+1')
            .to(woman_text3.current, {
                top: 0, y: 0,
                scrollTrigger: {
                    start: "35% top",
                    end: "50% top",
                    trigger: box5Ref.current,
                    scrub: true,
                },
            })

        const tl6 = gsap.timeline({
            scrollTrigger: {
                start: "50% top",
                end: "65% top",
                trigger: box5Ref.current,
                scrub: true,
            },
        })
            .to(woman_text4.current, { opacity: 1 })
            .to(woman_text4.current, { opacity: 0 }, '+1')
            .to(woman_text4.current, {
                top: 0, y: 0,
                scrollTrigger: {
                    start: "50% top",
                    end: "65% top",
                    trigger: box5Ref.current,
                    scrub: true,
                },
            })
        const tl7 = gsap.timeline({
            scrollTrigger: {
                start: "65% top",
                end: "80% top",
                trigger: box5Ref.current,
                scrub: true,
            },
        })
            .to(woman_text5.current, { opacity: 1 })
            .to(woman_text5.current, { opacity: 0 }, '+1')
            .to(woman_text5.current, {
                top: 0, y: 0,
                scrollTrigger: {
                    start: "65% top",
                    end: "80% top",
                    trigger: box5Ref.current,
                    scrub: true,
                },
            })
    }, [])
    useEffect(() => {
        gsap.to(block8CoverRef.current, {
            opacity: isBigScreen ? 0 : 0.2,
            scrollTrigger: {
                start: "top top",
                end: "10% top",
                trigger: box5Ref.current,
                scrub: true,
            }
        })
    }, [isBigScreen])
    useEffect(() => {
        let canvas = canvasRef.current
        if (!!canvas) {
            canvas.width = size.width
            canvas.height = size.height
        }
        if (imagesAry.length > 0) {
            updateImage(curIdx, imagesAry)
        }
    }, [size, imagesAry, curIdx])

    const updateImage = (index, imglist) => {
        let canvas = canvasRef.current
        let ctx = canvas.getContext("2d");
        const { sx, sy, swidth, sheight, x, y, width, height } = getObjectFitSize('cover', size.width, size.height, 2560, 1440)
        ctx.drawImage(imglist[index], sx, sy, swidth, sheight, 0, 0, width, height);
    }
    return (
        <div className={style.box} ref={boxRef}>
            <div className={style.box1} ref={box1Ref}></div>
            <div className={style.box2} ref={box2Ref}></div>
            <div className={style.box3} ref={box3Ref}></div>
            <div className={style.box4} ref={box4Ref}></div>
            <div className={style.box5} ref={box5Ref}></div>
            <div className={style.boxSticky} ref={boxStickyRef}>
                {/* 第一屏 */}
                <div className={style.block1} ref={block1Ref} style={{ backgroundImage: `url(${ultra_glass_1})` }}></div>
                <div className={style.text1} ref={text1Ref}>
                    <img src={air2ultra_logo} />
                    <span>
                        {intl.formatMessage({ id: "ces.ultra.title", defaultMessage: "Spatial Computing in a whole new light." })}</span>
                    <div className={style.text1Buy} onClick={()=>{window.open(ultra_link)
                    useGa('Home','home_buy_button', "Click")
                      sensors.track('module_interaction', {
                        custom: {
                            module_id: 20064
                        }
                    });
                    useGTM('products_click','Buy Now','Air 2 Ultra','promotion_Air 2 Ultra')
                    }}>  {intl.formatMessage({ id: "ces.ultra.desc1", defaultMessage: "Buy Now" })} <LinkArrow /></div>
                </div>
                <div className={style.block2} ref={block2Ref} style={{ backgroundImage: `url(${ultra_modal})` }}></div>

                <div className={style.block3} ref={block3Ref}></div>

                {/* 第二屏视频 */}
                <div className={style.block4} ref={block4Ref}>
                    <video autoPlay playsInline loop muted width="100%" >
                        <source src={firstVideo} type="video/mp4" />
                    </video>
                </div>
                {/* 第二屏视频的文字 */}
                <div className={style.block5}>
                    <div className={style.text2} ref={text2Ref} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "ces.ultra.desc2" })}` }}>
                    </div>
                    <div className={style.text3} ref={text3Ref}>{intl.formatMessage({ id: "ces.ultra.desc3" })}
                    </div>
                </div>

                <div className={style.block6} ref={block6Ref}>
                    <div className={style.block6Bg} ref={block6BgRef} style={{ backgroundImage: `url(${block6_bg})` }}></div>
                    <div className={style.block6Text} ref={block6TextRef}>
                        <div className={style.text48} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "ces.sensor.title1" })}` }}></div>
                        <div className={style.block6Desc}>
                            <div className={`${style.block6DescItem} ${style.text24}`}>
                                <span></span>{intl.formatMessage({ id: "ces.sensor.desc1" })}
                            </div>
                            <div className={`${style.block6DescItem} ${style.text24}`}>
                                <span></span><div dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "ces.sensor.desc2" })}` }}></div>
                            </div>
                            <div className={`${style.block6DescItem} ${style.text24}`}>
                                <span></span><div  dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "ces.sensor.desc3" })}`}}></div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={style.block7} ref={block7Ref}>
                    <div className={style.block7Bg} ref={block7BgRef} style={{ backgroundImage: `url(${block7_bg})` }}></div>
                    <div className={style.block7Text} ref={block7TextRef}>
                        <div className={style.text48} >{intl.formatMessage({ id: "ces.sensor.desc4" })}</div>
                        <div className={`${style.block7TextDesc} ${style.text24}`}>{intl.formatMessage({ id: "ces.sensor.desc5" })}</div>
                    </div>
                </div>
                <div className={style.block8} ref={block8Ref}>
                    <div className={style.woman} style={{ backgroundImage: `url(${firstImg})` }} ref={womanRef}>
                        <canvas ref={canvasRef}
                            width={size.width}
                            height={size.height} />
                    </div>
                    <div className={style.block8Cover} ref={block8CoverRef}></div>
                    <div className={style.block9}>
                        <div className={style.block9_text} ref={woman_text1}>
                            <div className={style.block9_title} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "ces.sensor.desc6" })}` }}></div>
                            <div className={style.block9_desc}>{intl.formatMessage({ id: "ces.sensor.desc7" })}</div>
                        </div>
                        <div className={`${style.block9_text} ${style.block9_text_r}`} ref={woman_text2}>
                            <div className={style.block9_title} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "ces.engine.title1" })}` }}></div>
                            <ul className={style.block9_desc}>
                                <li>{intl.formatMessage({ id: "ces.engine.desc1" })}</li>
                                <li>{intl.formatMessage({ id: "ces.engine.desc2" })}</li>
                                <li>{intl.formatMessage({ id: "ces.engine.desc3" })}</li>
                                <li>{intl.formatMessage({ id: "ces.engine.desc4" })}</li>
                            </ul>
                        </div>
                        <div className={style.block9_text} ref={woman_text3}>
                            <div className={style.block9_title} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "ces.engine.title2" })}` }}></div>
                            <ul className={style.block9_desc}>
                                <li>{intl.formatMessage({ id: "ces.engine.desc6" })}</li>
                                <li>{intl.formatMessage({ id: "ces.engine.desc7" })}</li>
                                <li>{intl.formatMessage({ id: "ces.engine.desc8" })}</li>
                            </ul>
                        </div>
                        <div className={`${style.block9_text} ${style.block9_text_r}`} ref={woman_text4}>
                            <div className={style.block9_title} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "ces.system.title1" })}` }}></div>
                            <div className={style.block9_desc}>
                            {intl.formatMessage({ id: "ces.system.desc1" })}
                            </div>
                        </div>
                        <div className={`${style.block9_text} ${style.block9_text_r}`} ref={woman_text5}>
                            <div className={style.block9_title}> {intl.formatMessage({ id: "ces.system.desc2" })}</div>
                            <div className={style.block9_desc} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "ces.system.desc3" })}` }}>
                           
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default FirstScreen